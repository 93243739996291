.longButton>switch-handle btn btn-light {
  display: none;
}

@media print {
  div.pdffooter {
    position: fixed;
    bottom: 0;
  }
}

.datepickerHeight {
  height: 58px;
  display: none;
}

/*
.notification:not(.notification-flush) {
    border: none !important;
}*/

.contractScroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 242px;
  padding-top: 0.5rem
}

.react-datepicker-popper {
  z-index: 10
}

.card-header:not(.h-auto) {
	height: 56px
}

.css-1s2u09g-control {
  height: 100%
}

.css-1pahdxg-control {
  height: 100%;
  padding-bottom: 5px
}

#react-select-3-listbox {
  margin: 0;
  padding: 0;
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

#customScroll::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 10px;
  border: 5px solid#edf2f9;
}


#customScroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#owner-payment::before {
  border: 1px solid rgb(221, 31, 31);
}

#client-payment::before {
  border: 1px solid #41af38
}

#installmentTimeline::before {
  border: 1px solid #2c7be5;
}

.timeline-vertical .timeline-item-end .timeline-item-content:before {}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d3d1d1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.customInputGroupText>label {
  margin-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #1a5a88;
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.customCheckboxNoMargin>label {
  margin-bottom: 0 !important;
}


.showOnPrint {
  display: none;
}


@media print {
  body {
    zoom: 65%;
  }

  .hideOnPrint {
    display: none !important;
  }

  .showOnPrint {
    display: initial !important;
  }

  .lowPaddingOnPrint {
    padding-top: 1rem !important;
  }

  .noSidePaddingPrint {
    padding: 0 0 0 0 !important;
  }
}

.css-1insrsq-control {
  height: 100% !important;
}

body {
  font-size: 0.8333rem !important;
}

label {
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0 !important;
}

.form-check {
  margin-bottom: 0 !important;
}

.fs--1rem {
  font-size: 1rem !important;
}

.form-floating>label {
  left: unset !important;
}

/***************/
.valuePadding {
  position: absolute;
  right: 9px;
  height: 100%;
  display: flex;
  font-size: 15px;
  align-items: center;
  z-index: 4;
}

input[required]+label:after {
  content: '*';
}

.timeline-vertical .timeline-item-content:before {
  border-color: inherit !important;
}

.h-100c > div {
  height: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}